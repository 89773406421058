<!--
File:  Report_5_RoadwayDetails.vue
Description: produces the report for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown class='region-select-dropdown' :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='reloadData' :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selectedDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
          <md-button class="md-raised md-success" @click="exportToExcel" :disabled="btnDisabled">
            {{ $t('buttons.excel_export') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 200px" :md-label="$t('road_network.region')">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')">
            {{ item.deu_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.road_key')">
            {{ item.road_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.from_km')" md-numeric>
            {{ item.start_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.to_km')" md-numeric>
            {{ item.end_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length')" md-numeric>
            {{ item.length_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.surface_type_desc')">
            {{ item.surface_type_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.width_m')" md-numeric>
            {{ item.width_m | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('condition.terrain_type_desc')">
            {{ item.terrain_type_description }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="md-layout">
        <!--<div>{{ $t('total_regions: ') }}: {{ total_regions }}&nbsp; </div>
            <div>{{ $t('total_deu: ') }}: {{ total_deu }}&nbsp; </div>-->
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
import { numFormat } from "@/store/helpers/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  mixins: [customSortMixin, printReport],
  name: 'report-road-way-details',
  data() {
    return {
      selectedRegion: null,
      selectedDate: null,
      selectedDeu: null,
      btnDisabled: true,

      showSpinner: false,
      currentSort: 'region_description',
      currentSortOrder: 'asc',
      theDate: new Date()
    }
  },
  components: {
    RegionsDropdown,
    DepsDropdown
  },

  created() {
    this.theDate.setHours(0, 0, 0)
    this.selectedDate = this.theDate
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      const report_filters = {
        region_id: this.selectedRegion,
        deu_id: this.selectedDeu,
        selectedDate: this.selectedDate.toISOString().substring(0, 10)
      }
      this.$store.dispatch('REPORT_5_ROADWAY_DETAILS', report_filters).then(() => {
        this.btnDisabled = false
        this.showSpinner = false
      })
    },

    print() {
      this.showSpinner = true
      this.printReport(this.getPrintHeader, this.getTableHeaders, this.getTableRows)
      this.showSpinner = false
    },

    setWorkSheetHeader(worksheet) {
      let titleRow = worksheet.insertRow(1, [this.$t('label.reports_title')])
      titleRow.font = { bold: true, size: 16 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('A1:G1');

      titleRow = worksheet.insertRow(2, [
        `${this.$t('route.report_road_way_details')} ${this.$t('label.as_on')} ${this.selectedDate.toLocaleDateString()}`
      ]);
      titleRow.font = { size: 14 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('A2:G2');

      if (this.headerTitles.length > 0) {
        titleRow = worksheet.insertRow(3, [this.headerTitles.join(', ')]);
        titleRow.font = { size: 14 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A3:G3');
      }
    },

    fillWorkSheet(workbook, workbookName) {
      const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
      const worksheet = workbook.addWorksheet(workbookName);

      // Set the table headers
      const columns = this.getWorksheetColumns
      worksheet.columns = columns

      // Apply formatting to the table headers first  row
      let newRow = worksheet.getRow(1);
      newRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }
      newRow.fill = headerCellFill
      newRow.alignment = { vertical: 'middle', horizontal: 'center' }

      // Number formatting for the columns
      columns.forEach(item => {
        if (item.num) worksheet.getColumn(item.key).numFmt = item.digits == 3 ? '#,##0.000' : '#,##0.00'
      })

      this.report.forEach(item => {
        worksheet.addRow(item);
      });

      // Set the worksheet header, should be after filling the table
      this.setWorkSheetHeader(worksheet)
    },

    async exportToExcel() {
      // Create new Excel file
      const workbook = new ExcelJS.Workbook();
      const wbName = this.$t('route.report_road_way_details')
      this.fillWorkSheet(workbook, wbName)

      // Save
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${wbName}.xlsx`);
    },
  },

  computed: {
    ...mapState({
      report_5_data: (state) => state.Reports.report_5_data,
    }),

    ...mapGetters([]),

    report() {
      return this.customSort(this.report_5_data
        .map(item => {
          return {
            ...item,
            length_km: item.end_km - item.start_km,
            deu_description: `${this.$t('road_network.dep')}-${item.deu_description}`
          }
        }), 'deu_description')
    },

    getWorksheetColumns() {
      let res = [
        { header: this.$t('road_network.road_key'), key: 'road_description', width: 50 },
        { header: this.$t('label.from_km'), key: 'start_km', width: 10, num: true, digits: 3 },
        { header: this.$t('label.to_km'), key: 'end_km', width: 10, num: true, digits: 3 },
        { header: this.$t('road_network.length'), key: 'length_km', width: 10, num: true, digits: 3 },
        { header: this.$t('condition.surface_type_desc'), key: 'surface_type_description', width: 15 },
        { header: this.$t('label.width_m'), key: 'width_m', width: 10, num: true, digits: 2 },
        { header: this.$t('condition.terrain_type_desc'), key: 'terrain_type_description', width: 25 },
      ];

      if (!this.selectedDeu) {
        res.unshift({ header: this.$t('road_network.dep'), key: 'deu_description', width: 10 });
      }

      if (!this.selectedRegion) {
        res.unshift({ header: this.$t('road_network.region'), key: 'region_description', width: 20 });
      }
      return res
    },

    headerTitles() {
      const titleFiltersArray = []

      if (this.selectedRegion) {
        titleFiltersArray.push(this.$t('label.from_region', { name: this.report[0].region_description }));
      }

      if (this.selectedDeu) {
        titleFiltersArray.push(`${this.$t('road_network.dep')}: ${this.report[0].deu_description}`);
      }
      return titleFiltersArray
    },

    getPrintHeader() {
      const titleFilters = this.headerTitles.length > 0 ? `<br>(${this.headerTitles.join(', &nbsp;')})` : '';

      return `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
        <h4 style='text-align:center'>
          ${this.$t('route.report_road_way_details')}
          ${this.$t('label.as_on')}${this.selectedDate.toLocaleDateString()}
          ${titleFilters}
        </h4>`;
    },

    getTableHeaders() {
      const columns = this.getWorksheetColumns
      return `<tr>${columns.map(col => `<th>${col.header}</th>`).join('')}</tr>`
    },

    getTableRows() {
      const columns = this.getWorksheetColumns

      return this.report.map(item => {
        return `<tr>${columns.map(col => col.num
          ? `<td class='numCell'>${numFormat(item[col.key], col.digits)}</td>`
          : `<td>${item[col.key]}</td>`).join('')}
        </tr>`;
      }).join('');
    },
  },

  watch: {
    selectedDate(newValue, oldValue) {
      if (!newValue) this.selectedDate = this.theDate
      else this.reloadData()
    },
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 14px !important;
  text-align: center !important;
  overflow: visible !important;
  white-space: normal !important;
  padding-right: unset !important;
}

.md-table-cell.md-numeric {
  padding: 0px !important;
  padding-right: 18px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>